<template>
    <div v-html="html"></div>
</template>
<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
// import { dir } from "console";
export default {
    data() {
        return {
            html: '',
        }
    },
    // created() {
    //     this.submitFormUser();
    // },
    methods: {
        fetchVideoPay() {
            // const newTab = window.open();
            let param = {
                orderJg: this.$route.query.jg,
                price: this.$route.query.price,
                userId: this.$route.query.userId,
                priceName: this.$route.query.priceName,
                appId: this.$route.query.appId,
            };
            api.postAlipay(param).then(res => {
                // this.html = res.data;
                // console.log(res.data)
                window.location.href=res.data.alipay_url;
            })
        },
        //发送绑定
        submitFormUser(params) {
            const newTab = window.open();
            api.postAlipay(params).then((res) => {
                console.table(res);
                if (res.status == 200) {
                    // this.$toast("绑定成功！")
                    console.log("=============00000000000000000000000");
                    console.log(res.data)
                    // window.open(innerHtml(res.data), "_blank");
                    // this.sqfk()

                    // window.open(res.data.innerHTML, '_ blank')

                    const div = document.createElement('div');
                    div.innerHTML = res.data; // html code
                    document.body.appendChild(div);
                    document.forms.alipaysubmit.submit();
                } else {
                    console.log("=============33333333333333333333333");
                }
            });
        },
    },
    mounted() {
        this.fetchVideoPay()
    }
}
</script>